import { NavigateFunction } from "react-router-dom";

/**
 * Navigation Service - Provides app-wide navigation capabilities
 * This service decouples direct navigation from components and services
 */
class NavigationService {
  private navigate: NavigateFunction | null = null;

  /**
   * Initialize the navigation service with React Router's navigate function
   */
  setNavigate(navigateFunction: NavigateFunction): void {
    this.navigate = navigateFunction;
  }

  /**
   * Navigate to a specified path
   */
  navigateTo(path: string, options?: { replace?: boolean; state?: Record<string, unknown> }): void {
    if (this.navigate) {
      this.navigate(path, options);
    } else {
      console.error("Navigation function not initialized");
      // Only fallback to window.location as a last resort when React Router's navigate isn't available
      // This should be rare since navigation service is initialized in App.tsx
      if (options?.replace) {
        window.location.replace(path);
      } else {
        window.location.href = path;
      }
    }
  }

  /**
   * Navigate to login with a reason
   */
  navigateToLogin(reason = "session_expired"): void {
    this.navigateTo(`/login?reason=${reason}`, { 
      replace: true,
      // Reset any authentication-related state to ensure a clean login experience
      state: { authError: true, reason }
    });
  }
}

// Export a singleton instance
export const navigationService = new NavigationService();

// Setup auth-navigate event listener
window.addEventListener("auth-navigate", ((event: CustomEvent<{ path: string; state?: Record<string, unknown> }>) => {
  navigationService.navigateTo(event.detail.path, { 
    replace: true,
    state: event.detail.state
  });
}) as EventListener);

export default navigationService;