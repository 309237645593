import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { tokenManager } from "./tokenManager";
import { API_URL, API_TIMEOUT } from "./apiConfig";

/**
 * Custom axios request configuration with additional auth-related properties
 */
export interface CustomConfig extends AxiosRequestConfig {
  skipAuth?: boolean;
  isVerification?: boolean;
  _retry?: boolean;
}

/**
 * Create and configure API client instance
 */
const apiClient: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

/**
 * Request interceptor for API calls
 */
apiClient.interceptors.request.use(async (config: CustomConfig) => {
  // Skip auth if explicitly requested
  if (config.skipAuth) {
    return config;
  }

  // Handle verification requests differently
  if (config.isVerification) {
    const authSession = tokenManager.getAuthSession();
    if (config.headers) {
      config.headers.Authorization = `Bearer ${authSession}`;
    }
    return config;
  }

  // Check for tokens
  if (!tokenManager.hasTokens()) {
    tokenManager.handleSessionExpiration();
    throw new axios.Cancel("No tokens available");
  }

  // Refresh token if needed
  if (tokenManager.isAccessTokenAboutToExpire()) {
    try {
      await tokenManager.refreshToken();
    } catch (error) {
      throw new axios.Cancel("Session maintenance failed");
    }
  }

  // Add access token to all authenticated requests
  if (config.headers) {
    config.headers.Authorization = `Bearer ${tokenManager.getAccessToken()}`;
  }
  
  return config;
});

/**
 * Response interceptor for API calls
 */
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config as CustomConfig;
    
    // Only attempt refresh on 401 and not for already retried requests
    if (error.response?.status !== 401 || originalRequest._retry) {
      return Promise.reject(error);
    }
    
    // Mark request as retried
    originalRequest._retry = true;
    
    try {
      // Refresh token and retry the original request
      await tokenManager.refreshToken();
      return apiClient(originalRequest);
    } catch (refreshError) {
      return Promise.reject(refreshError);
    }
  }
);

export default apiClient;