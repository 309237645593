/**
 * API configuration constants
 */

// Base URL for API calls
export const API_URL = import.meta.env.VITE_APP_API_URL || "https://quibench.io";

// API request timeout in milliseconds (20 seconds)
export const API_TIMEOUT = 20000;

// Add type declaration for import.meta.env
declare global {
  interface ImportMeta {
    env: {
      VITE_APP_API_URL?: string;
    };
  }
}