import React from "react";
import { Box, Typography, Button, Paper, styled, SxProps, Theme } from "@mui/material";
import { alpha } from "@mui/material/styles";

// Styled container for consistent error display
const ErrorContainer = styled(Paper)(({ theme }) => ({
  borderRadius: 12,
  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.05)",
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${alpha(theme.palette.error.light, 0.3)}`,
  minHeight: 200,
  width: "100%",
}));

// Available error states for different scenarios
export type ErrorType = 
  | 'network'  // Network connectivity issues
  | 'server'   // Server errors (500 range)
  | 'notFound' // Resource not found (404)
  | 'unauthorized' // Authentication issues (401/403)
  | 'validation' // Input validation errors
  | 'timeout'  // Request timeout
  | 'unknown';  // Fallback for unexpected errors

interface QueryErrorStateProps {
  error: Error | unknown;
  errorType?: ErrorType;
  title?: string;
  message?: string;
  onRetry?: () => void;
  showRetry?: boolean;
  className?: string;
  sx?: SxProps<Theme>;
  height?: string;
  status?: number; // HTTP status code if available
}

/**
 * QueryErrorState component - displays API/data fetching errors in a user-friendly way
 * with appropriate messaging based on error type.
 */
const QueryErrorState: React.FC<QueryErrorStateProps> = ({
  error,
  errorType = 'unknown',
  title,
  message,
  onRetry,
  showRetry = true,
  className,
  sx,
  height,
  status,
}) => {
  // Determine error details based on type or status
  const getErrorDetails = (): { title: string; message: string; icon: string } => {
    // Use status code to determine error type if available
    const derivedErrorType = status 
      ? getErrorTypeFromStatus(status) 
      : errorType;

    switch (derivedErrorType) {
      case 'network':
        return {
          title: 'Network Error',
          message: 'Unable to connect to the server. Please check your internet connection and try again.',
          icon: '/network-error.svg',
        };
      case 'server':
        return {
          title: 'Server Error',
          message: 'Our servers are experiencing issues. Our team has been notified and is working on a fix.',
          icon: '/server-error.svg',
        };
      case 'notFound':
        return {
          title: 'Not Found',
          message: 'The requested resource could not be found.',
          icon: '/not-found.svg',
        };
      case 'unauthorized':
        return {
          title: 'Access Denied',
          message: 'You do not have permission to access this resource.',
          icon: '/unauthorized.svg',
        };
      case 'validation':
        return {
          title: 'Validation Error',
          message: 'There was an issue with the data provided. Please check your inputs and try again.',
          icon: '/validation-error.svg',
        };
      case 'timeout':
        return {
          title: 'Request Timeout',
          message: 'The request took too long to complete. Please try again later.',
          icon: '/timeout.svg',
        };
      case 'unknown':
      default:
        return {
          title: 'Something Went Wrong',
          message: 'An unexpected error occurred. Please try again or contact support if the problem persists.',
          icon: '/error.svg',
        };
    }
  };

  // Helper to determine error type from HTTP status code
  const getErrorTypeFromStatus = (status: number): ErrorType => {
    if (status >= 500) return 'server';
    if (status === 404) return 'notFound';
    if (status === 401 || status === 403) return 'unauthorized';
    if (status === 422) return 'validation';
    if (status === 408) return 'timeout';
    return 'unknown';
  };

  // Get appropriate error details
  const errorDetails = getErrorDetails();
  
  // Use provided title/message or fall back to derived values
  const displayTitle = title || errorDetails.title;
  const displayMessage = message || errorDetails.message;
  
  // Extract message from error object if available
  const errorMessage = error instanceof Error ? error.message : '';

  return (
    <ErrorContainer 
      className={className} 
      sx={{ 
        ...sx, 
        height: height || "auto",
        minHeight: height ? 'unset' : 200,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 1.5,
          width: "100%",
        }}
      >
        <Box
          component="img"
          src={errorDetails.icon}
          alt="Error"
          sx={{ 
            width: 110, 
            height: 110, 
            mb: 2, 
            opacity: 0.9,
            // Fallback for missing images
            onError: (e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = '/error.svg';
            },
          }}
        />
        
        <Typography
          variant="h5"
          color="error"
          align="center"
          gutterBottom
          fontWeight={600}
        >
          {displayTitle}
        </Typography>
        
        <Typography
          variant="body1"
          color="text.secondary"
          align="center"
          sx={{ maxWidth: 500, mb: 1.5 }}
        >
          {displayMessage}
        </Typography>
        
        {/* Show detailed error message in development mode */}
        {process.env.NODE_ENV === 'development' && errorMessage && (
          <Typography
            variant="caption"
            color="text.secondary"
            align="center"
            sx={{ 
              maxWidth: 500, 
              mb: 2,
              p: 1.5,
              borderRadius: 1,
              fontFamily: 'monospace',
              width: '100%',
            }}
          >
            {errorMessage}
          </Typography>
        )}
        
        {showRetry && onRetry && (
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={onRetry}
            sx={{ mt: 1 }}
          >
            Try Again
          </Button>
        )}
      </Box>
    </ErrorContainer>
  );
};

export default QueryErrorState;