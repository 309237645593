import React from "react";

export const SidebarShortLogo: React.FC = () => {
    return (
        <>
            <svg width="40" height="40" viewBox="0 0 42 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 4L37 22L19 40" stroke="#B7A0E7" strokeWidth="6" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M11 10L23 22L11 34" stroke="#B7A0E7" strokeWidth="6" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <rect x="-2.07031" y="22" width="11" height="11" rx="5.5" transform="rotate(-45 -2.07031 22)"
                      fill="#B7A0E7"/>
            </svg>
        </>
    );
};
