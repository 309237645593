import apiClient, { CustomConfig } from "./apiClient";
import { tokenManager } from "./tokenManager";
import { AuthCredentials, TokenData, AuthSessionData } from "@/types/auth";

/**
 * Authentication service for handling login, registration, verification, and logout
 */
export const authService = {
  /**
   * Login with email and password
   */
  async login(credentials: AuthCredentials): Promise<TokenData | AuthSessionData> {
    // Convert credentials to form data format
    const params = new URLSearchParams();
    params.append("username", credentials.email);
    params.append("password", credentials.password);
    
    const { data } = await apiClient.post<TokenData | AuthSessionData>(
      "/api/public/auth/login",
      params,
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        skipAuth: true,
      } as CustomConfig
    );
    
    // Store tokens or auth session based on response type
    if ("refresh_token" in data && "access_token" in data) {
      tokenManager.setTokens(data);
    } else if ("authSession" in data) {
      tokenManager.setAuthSession(data.authSession);
    }
    
    return data;
  },
  
  /**
   * Register new user
   */
  async register(credentials: AuthCredentials): Promise<AuthSessionData> {
    const { data } = await apiClient.post<AuthSessionData>(
      "/api/public/auth/sign-up",
      {
        email: credentials.email,
        password: credentials.password,
      },
      { skipAuth: true } as CustomConfig
    );
    
    if ("authSession" in data) {
      tokenManager.setAuthSession(data.authSession);
    }
    
    return data;
  },
  
  /**
   * Verify email with verification code
   */
  async verifyEmail(code: string): Promise<TokenData> {
    const { data } = await apiClient.post<TokenData>(
      "/api/public/auth/email-verification",
      {},
      {
        params: { code },
        isVerification: true,
      } as CustomConfig
    );
    
    tokenManager.clearAuthSession();
    tokenManager.setTokens(data);
    
    return data;
  },
  
  /**
   * Logout user
   */
  logout(): void {
    tokenManager.handleSessionExpiration("logout");
  },
  
  /**
   * Check if user is authenticated
   */
  isAuthenticated(): boolean {
    return tokenManager.hasTokens();
  },
};