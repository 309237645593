import { Box, styled, Theme } from "@mui/material";
import { Outlet } from "react-router-dom";
import MainSidebar from "@/components/Workspace/MainSidebar";
import React, { Suspense } from "react";
import {WorkspaceProvider} from "@/store/workspaceStore.ts";
import Loading from "@/components/Common/Loading";

const WorkspaceContainer = styled(Box)(() => ({
    display: "flex",
    height: "100vh",
    width: "100%",
    overflow: "hidden"
}));

const MainContent = styled(Box)(({ theme }: { theme: Theme }) => ({
    flex: 1,
    height: "100vh",
    overflow: "hidden",
    padding: theme.spacing(1.5, 2),
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default
}));

const Workspace: React.FC = () => {
    return (
      <WorkspaceProvider>
          <WorkspaceContainer>
              <MainSidebar />
              <MainContent>
                  <Suspense fallback={<Loading />}>
                      <Outlet />
                  </Suspense>
              </MainContent>
          </WorkspaceContainer>
      </WorkspaceProvider>
    );
};

export default Workspace;