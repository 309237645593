import { DashboardSvg } from '@/assets/svg/Sidebar/DashboardSvg.tsx';
import { InterviewsSvg } from '@/assets/svg/Sidebar/InterviewsSvg.tsx';
import { ReportsSvg } from '@/assets/svg/Sidebar/ReportsSvg.tsx';
import { SalesSvg } from '@/assets/svg/Sidebar/SalesSvg.tsx';
import { SidebarDesktopDarkLogo } from '@/assets/svg/Sidebar/SidebarDesktopDarkLogo.tsx';
import { SidebarDesktopLightLogo } from '@/assets/svg/Sidebar/SidebarDesktopLightLogo.tsx';
import { authService } from '@/services/auth/authService.ts';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  useTheme,
  Tooltip,
  Theme,
  alpha
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useLocation, useNavigate } from "react-router-dom";
import { ExitToApp, Help, Person, Settings } from "@mui/icons-material";
import {useSelectedSidebarElement} from "@/store/sidebarStore.ts";
import {SidebarShortLogo} from "@/assets/svg/Sidebar/SidebarShortLogo.tsx";

// Constants
const SIDEBAR_WIDTHS = {
  EXPANDED: 200,
  COLLAPSED: 65
} as const;

const ANIMATION_DURATION = {
  FAST: '0.2s',
  NORMAL: '0.3s'
} as const;

// Types
interface SidebarItem {
  id: string;
  label: string;
  icon: React.ReactElement;
  route?: string;
}

interface CollapsibleProps {
  collapsed: boolean;
}

interface NavButtonProps extends CollapsibleProps {
  selected: boolean;
}

interface NavigationButtonProps {
  item: SidebarItem;
  isSelected: boolean;
  isCollapsed: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface SidebarCollapseHook {
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

/**
 * Styled Components
 */
const SidebarContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'collapsed'
})<CollapsibleProps>(({ theme, collapsed }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  padding: theme.spacing(0, 1, 1, 1),
  width: collapsed ? SIDEBAR_WIDTHS.COLLAPSED : SIDEBAR_WIDTHS.EXPANDED,
  minWidth: collapsed ? SIDEBAR_WIDTHS.COLLAPSED : SIDEBAR_WIDTHS.EXPANDED, // Ensures minimum width
  transition: `all ${ANIMATION_DURATION.NORMAL} cubic-bezier(0.4, 0, 0.2, 1)`,
  flexShrink: 0,
  overflowX: "hidden",
  border: "none",
  boxShadow: "none",
  height: "100vh",
  overflow: "visible",
  borderRight: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  willChange: "width", // Tells the browser to optimize for animations,
  "& .MuiButton-root": {
    minWidth: collapsed ? SIDEBAR_WIDTHS.COLLAPSED - theme.spacing(2) : "auto", // Ensures buttons have minimum width in collapsed mode
  }
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  height: theme.spacing(8), // Standardized height
  overflow: "hidden",
  "& svg": {
    maxWidth: "100%",
    height: "auto",
    transition: `all ${ANIMATION_DURATION.NORMAL} cubic-bezier(0.4, 0, 0.2, 1)`,
  },
}));

const ToggleButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: -15,
  top: "50%",
  transform: "translateY(-50%)",
  width: 30,
  height: 30,
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
  boxShadow: theme.shadows[2],
  zIndex: 1201,
  transition: `all ${ANIMATION_DURATION.FAST} cubic-bezier(0.4, 0, 0.2, 1)`,
  "&:active": {
    transform: "translateY(-50%) scale(0.95)",
  },
  "& svg": {
    transition: `transform ${ANIMATION_DURATION.FAST} cubic-bezier(0.4, 0, 0.2, 1)`,
  }
}));

const SidebarSection = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'collapsed'
})<CollapsibleProps>(({ theme, collapsed }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  width: "100%",
  alignItems: collapsed ? "center" : "flex-start",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.divider}`,
  transition: `all ${ANIMATION_DURATION.NORMAL} cubic-bezier(0.4, 0, 0.2, 1)`,
}));

const NavButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'collapsed' && prop !== 'selected'
})<NavButtonProps>(({ theme, selected, collapsed }) => ({
  justifyContent: collapsed ? "center" : "flex-start",
  textTransform: "none",
  borderRadius: theme.shape.borderRadius,
  padding: collapsed ? theme.spacing(1) : theme.spacing(1, 2),
  width: "100%",
  minWidth: "auto",
  height: theme.spacing(5), // Standard height for all buttons
  transition: `all ${ANIMATION_DURATION.NORMAL} cubic-bezier(0.4, 0, 0.2, 1)`,
  fontFamily: theme.typography.fontFamily,
  ...(selected && {
    backgroundColor: theme.palette.mode === 'dark' 
      ? alpha(theme.palette.secondary.main, 0.15)
      : theme.palette.action.selected,
    boxShadow: theme.palette.mode === 'dark' 
      ? `0 0 6px ${alpha(theme.palette.secondary.main, 0.2)}` 
      : 'none',
  }),
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    transform: "translateX(2px)",
  },
  "& .MuiButton-startIcon": {
    marginRight: collapsed ? 0 : theme.spacing(1),
    marginLeft: collapsed ? 0 : 0,
    transition: `margin ${ANIMATION_DURATION.NORMAL} cubic-bezier(0.4, 0, 0.2, 1)`,
    display: "flex", // Ensure icon is displayed
    [theme.breakpoints.up("md")]: {
      marginRight: collapsed ? 0 : theme.spacing(1.5),
    },
  },
  "& .MuiButton-endIcon": {
    display: "none", // Hide end icon in collapsed mode
  },
  "& span.MuiButton-startIcon": {
    display: "flex", // Force display of icon wrapper
  },
  "& span.MuiButton-label": {
    justifyContent: "center", // Center the button content
  },
  "& .MuiButton-text": {
    fontSize: theme.typography.pxToRem(13),
    transition: `opacity ${ANIMATION_DURATION.FAST} cubic-bezier(0.4, 0, 0.2, 1)`,
    opacity: collapsed ? 0 : 1,
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  [theme.breakpoints.up("md")]: {
    padding: collapsed
      ? theme.spacing(1)
      : theme.spacing(1, 2),
  },
  "&:hover .MuiSvgIcon-root": {
    color: theme.palette.text.primary,
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  padding: theme.spacing(0.5),
  width: theme.spacing(3.5),
  height: theme.spacing(3.5), // Fixed size for consistent appearance
  minWidth: theme.spacing(3.5), // Ensures the icon has a minimum width
  minHeight: theme.spacing(3.5), // Ensures the icon has a minimum height
  flexShrink: 0, // Prevents the icon from shrinking
  transition: `all ${ANIMATION_DURATION.NORMAL} cubic-bezier(0.4, 0, 0.2, 1)`,
  ...(theme.palette.mode === 'dark' && {
    filter: 'drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.2))'
  }),
  "& svg": {
    transition: `all ${ANIMATION_DURATION.NORMAL} cubic-bezier(0.4, 0, 0.2, 1)`,
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    display: "block", // Ensures the icon is displayed as a block element
    flexShrink: 0, // Prevents the icon from shrinking
    "& path:not([fill='none'])": {
      fill: theme.palette.mode === 'dark' ? theme.palette.common.white : undefined
    },
    [theme.breakpoints.down("lg")]: {
      width: theme.spacing(2.25),
      height: theme.spacing(2.25),
    },
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(2.25),
      height: theme.spacing(2.25),
    },
  },
}));

/**
 * Custom Hook for sidebar collapse state
 */
const useSidebarCollapse = (): SidebarCollapseHook => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    localStorage.getItem('sidebarCollapsed') === 'true'
  );
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);

  const toggleCollapse = useCallback((): void => {
    if (isTransitioning) return; // Prevent toggling during transition
    
    setIsTransitioning(true);
    const newState = !isCollapsed;
    setIsCollapsed(newState);
    localStorage.setItem('sidebarCollapsed', newState.toString());
    
    // Reset transition lock after animation completes
    setTimeout(() => {
      setIsTransitioning(false);
    }, 300); // Match with ANIMATION_DURATION.NORMAL in ms
  }, [isCollapsed, isTransitioning]);

  return { isCollapsed, toggleCollapse };
};

/**
 * Navigation item data
 */
const MENU_ITEMS: SidebarItem[] = [
  {
    id: "dashboard",
    label: "Dashboard",
    icon: <DashboardSvg />,
    route: "/app/dashboard",
  },
  {
    id: "interviews",
    label: "Interviews",
    icon: <InterviewsSvg />,
    route: "/app/interviews",
  },
  // {
  //   id: "knowledge",
  //   label: "Knowledge",
  //   icon: <KnowledgeSvg />,
  //   route: "/app/knowledge",
  // },
  {
    id: "sales",
    label: "Sales",
    icon: <SalesSvg />,
    route: "/app/sales",
  },
  {
    id: "reports",
    label: "Reports",
    icon: <ReportsSvg />,
    route: "/app/reports",
  },
];

const SETTINGS_ITEMS: SidebarItem[] = [
  { id: "profile", label: "Profile", icon: <Person /> },
  { id: "settings", label: "Settings", icon: <Settings /> },
  { id: "help", label: "Get Help", icon: <Help /> },
  { id: "logout", label: "Logout", icon: <ExitToApp /> },
];

/**
 * Navigation Button Component
 */
const NavigationButton: React.FC<NavigationButtonProps> = ({
  item,
  isSelected,
  isCollapsed,
  onClick
}) => {
  const theme = useTheme<Theme>();

  const iconElement = (
    <IconWrapper
      sx={{
        background: isSelected
          ? theme.palette.mode === 'dark' 
            ? `linear-gradient(97.84deg, ${alpha(theme.palette.secondary.main, 0.3)} 3.02%, ${alpha(theme.palette.secondary.dark, 0.15)} 140.19%)`
            : "linear-gradient(97.84deg, #E3DBF4 3.02%, #CDC0E9 140.19%)"
          : "transparent",
        boxShadow: isSelected && theme.palette.mode === 'dark' 
          ? `0 0 8px ${alpha(theme.palette.secondary.main, 0.25)}`
          : 'none',
        transform: isSelected ? "scale(1.05)" : "scale(1)",
      }}
    >
      {React.cloneElement(item.icon, {
        sx: {
          color: isSelected
            ? theme.palette.mode === 'dark'
              ? theme.palette.secondary.main
              : theme.palette.primary.main
            : theme.palette.mode === 'dark'
              ? alpha(theme.palette.common.white, 0.7)
              : theme.palette.text.secondary,
          opacity: theme.palette.mode === 'dark' ? 0.95 : 0.8,
          '& path': {
            fill: theme.palette.mode === 'dark' && !isSelected
              ? alpha(theme.palette.common.white, 0.8)
              : undefined
          }
        },
      })}
    </IconWrapper>
  );

  return (
    <Tooltip
      title={isCollapsed ? item.label : ""}
      placement="right"
      arrow
      enterDelay={500}
    >
      <NavButton
        selected={isSelected}
        collapsed={isCollapsed}
        onClick={onClick}
        aria-label={item.label}
        startIcon={iconElement}
      >
        {!isCollapsed && (
          <Box
            component="span"
            sx={{
              fontWeight: 500,
              color: isSelected
                ? theme.palette.mode === 'dark'
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main
                : theme.palette.text.secondary,
              fontSize: "0.875rem",
              transition: `all ${ANIMATION_DURATION.NORMAL} cubic-bezier(0.4, 0, 0.2, 1)`,
              whiteSpace: 'nowrap',
            }}
          >
            {item.label}
          </Box>
        )}
      </NavButton>
    </Tooltip>
  );
};

/**
 * Main Sidebar Component
 */
const MainSidebar: React.FC = () => {
  const theme = useTheme<Theme>();
  const navigate = useNavigate();
  const location = useLocation();
  const { isCollapsed, toggleCollapse } = useSidebarCollapse();

  const { selectedSidebarElement, setSelectedSidebarElement } =
    useSelectedSidebarElement();

  // Set selected element based on URL path
  useEffect(() => {
    const currentSection = location.pathname.split("/")[2];
    if (
      currentSection &&
      MENU_ITEMS.some((item) => item.id === currentSection)
    ) {
      setSelectedSidebarElement(currentSection);
    }
  }, [location, setSelectedSidebarElement]);

  // Handle settings item clicks
  const handleSettingsClick = useCallback((itemId: string) => (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setSelectedSidebarElement(itemId);

    switch (itemId) {
      case "logout":
        authService.logout();
        break;
      case "profile":
        navigate("/app/profile");
        break;
      case "settings":
        navigate("/app/settings");
        break;
      case "help":
        const email = "kiryl.rusanau@quibench.io";
        const subject = encodeURIComponent("Help Request");
        const body = encodeURIComponent("Please describe your issue here:");
        window.open(`mailto:${email}?subject=${subject}&body=${body}`, "_self");
        break;
      default:
        break;
    }
  }, [navigate, setSelectedSidebarElement]);

  // Handle navigation item clicks
  const handleNavigation = useCallback((item: SidebarItem): void => {
    if (item.route) {
      navigate(item.route);
    }
    setSelectedSidebarElement(item.id);
  }, [navigate, setSelectedSidebarElement]);

  // Memoize the sidebar content to prevent unnecessary re-renders
  const sidebarContent = useMemo(() => (
    <>
      <LogoContainer>
        <Box 
          sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: `all ${ANIMATION_DURATION.NORMAL} cubic-bezier(0.4, 0, 0.2, 1)`,
            opacity: 1,
            width: '100%'
          }}
        >
          {!isCollapsed ? (
            <>
            {theme.palette.mode === 'dark' ? <SidebarDesktopLightLogo /> : <SidebarDesktopDarkLogo />}
            </>
          ) : (
              <SidebarShortLogo />
          )}
        </Box>
      </LogoContainer>

      {/* Collapse toggle button */}
      <ToggleButton
        onClick={toggleCollapse}
        size="small"
        aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
      >
        {isCollapsed ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
      </ToggleButton>

      {/* Main navigation items */}
      <SidebarSection
        collapsed={isCollapsed}
        sx={{
          pt: 2,
          height: 'auto',
          opacity: 1,
          transform: 'translateX(0)',
          animation: `${ANIMATION_DURATION.FAST} cubic-bezier(0.4, 0, 0.2, 1)`,
        }}
        role="navigation"
        aria-label="Main Navigation"
      >
        {MENU_ITEMS.map((item, index) => (
          <Box
            key={item.id}
            sx={{
              width: '100%',
              transition: `transform ${ANIMATION_DURATION.NORMAL} cubic-bezier(0.4, 0, 0.2, 1) ${index * 0.03}s`,
              transform: 'translateX(0)',
            }}
          >
            <NavigationButton
              item={item}
              isSelected={selectedSidebarElement === item.id}
              isCollapsed={isCollapsed}
              onClick={() => handleNavigation(item)}
            />
          </Box>
        ))}
      </SidebarSection>

      {/* Settings items */}
      <SidebarSection
        collapsed={isCollapsed}
        sx={{ mt: "auto" }}
        role="navigation"
        aria-label="Settings Navigation"
      >
        {SETTINGS_ITEMS.map((item, index) => (
          <Box
            key={item.id}
            sx={{
              width: '100%',
              transition: `transform ${ANIMATION_DURATION.NORMAL} cubic-bezier(0.4, 0, 0.2, 1) ${index * 0.03}s`,
              transform: 'translateX(0)',
            }}
          >
            <NavigationButton
              item={item}
              isSelected={selectedSidebarElement === item.id}
              isCollapsed={isCollapsed}
              onClick={handleSettingsClick(item.id)}
            />
          </Box>
        ))}
      </SidebarSection>
    </>
  ), [isCollapsed,
    selectedSidebarElement,
    handleNavigation,
    handleSettingsClick,
    toggleCollapse,
    theme.palette.mode]);

  return (
    <>
      <SidebarContainer collapsed={isCollapsed}>
        {sidebarContent}
      </SidebarContainer>
    </>
  );
};

export default MainSidebar;