import apiClient from '@/services/auth/apiClient.ts';
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import * as React from "react";

// Define the User interface based on the data structure from the API
export interface User {
  userId: string;
  spaceId: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  contactEmail: string;
  contactPhone: string;
  workingStartDate: string;
  generalExpInYears: number;
  position: string;
  department: string;
  timeZone: string;
  location: string;
  linkedin: string | null;
  telegram: string | null;
  whatsapp: string | null;
  slack: string | null;
  createdAt: string;
  updatedAt: string;
  seniority: string | null;
  linkToCv: string | null;
}

interface UpdateUserDataParams {
  selectedWorkspaceId: string;
  values: User;
}

export interface Workspace {
  id: string;
  name: string;
}

export interface SeniorityLevel {
  seniority: string;
  label: string;
}

export interface SelectOption {
  value: string;
  label: string;
}

export const fetchUserData = async (selectedWorkspaceId: string): Promise<User> => {
  try {
    const response = await apiClient.get("/api/public/core/users/profiles/me", {
      headers: {"X-space-id": selectedWorkspaceId}
    });
    return response.data;
  } catch (error: unknown) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};

export const fetchWorkspaces = async (): Promise<Workspace[]> => {
  try {
    const response = await apiClient.get("/api/public/core/spaces/me");
    return response.data;
  } catch (error: unknown) {
    console.error("Error fetching workspaces:", error);
    throw error;
  }
};

export const updateUserData = async ({ selectedWorkspaceId, values }: UpdateUserDataParams) => {
  try {
    const response = await apiClient.put(
      "/api/public/core/users/profiles/me/update",
      values,
      {
        headers: { "X-space-id": selectedWorkspaceId }
      }
    );
    return response.data;
  } catch (error: unknown) {
    console.error("Error changing user information", error);
    throw error;
  }
};

export const useUpdateUserData = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUserData,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      queryClient.invalidateQueries({ queryKey: ["bench"] });
    },
    onError: (error: unknown) => {
      console.error("Failed to update profile:", error);
    },
  });
};

export const fetchSeniorityLevels = async (): Promise<SeniorityLevel[]> => {
  try {
    const response = await apiClient.get("/api/public/core/users/profiles/seniorities");
    return response.data;
  } catch (error: unknown) {
    console.error("Error fetching seniority levels", error);
    throw error;
  }
};

// Base hook that returns the raw seniority levels data
export const useSeniorityLevels = () => {
  return useQuery({
    queryKey: ["seniorityLevels"],
    queryFn: fetchSeniorityLevels,
    staleTime: 24 * 60 * 60 * 1000, // Cache for 24 hours since this rarely changes
    placeholderData: [] as SeniorityLevel[],
  });
};

// Enhanced hook that returns seniority levels as formatted select options
export const useSeniorityOptions = () => {
  const { data, isLoading, isError, error } = useSeniorityLevels();
  
  // Format the seniority levels as select options
  const options: SelectOption[] = React.useMemo(() => {
    if (!data) return [];
    
    // Use traditional loop to be ES5 compatible
    const result: SelectOption[] = [];
    for (let i = 0; i < data.length; i++) {
      result.push({
        value: data[i].seniority,
        label: data[i].label,
      });
    }
    return result;
  }, [data]);
  
  return {
    options,
    isLoading,
    isError,
    error,
    // Additional helper to find a seniority label by seniority code
    getSeniorityLabelByCode: React.useCallback((code: string | null): string => {
      if (!code || !data) return '';
      // Use a standard loop to avoid ES2015 array methods if targeting ES5
      for (let i = 0; i < data.length; i++) {
        if (data[i].seniority === code) {
          return data[i].label;
        }
      }
      return '';
    }, [data])
  };
};