import { create } from "zustand";

// Define the possible sidebar elements
type SidebarElement = "dashboard" | "interviews" | "sales" | "reports" | "knowledge" | "profile" | "settings" | "help";

// Define the store state interface
interface SidebarState {
  selectedSidebarElement: SidebarElement;
  setSelectedSidebarElement: (element: SidebarElement) => void;
}

export const useSelectedSidebarElement = create<SidebarState>((set) => ({
  selectedSidebarElement: "dashboard",
  setSelectedSidebarElement: (element) =>
    set({ selectedSidebarElement: element }),
}));

// Retrieve from localStorage or use default
const getSidebarVisibilityFromStorage = (): boolean => {
  if (typeof window === 'undefined') return true;
  const stored = localStorage.getItem('interviewSidebarVisible');
  return stored !== null ? JSON.parse(stored) : true;
};

// Save to localStorage
const saveSidebarVisibilityToStorage = (visible: boolean): void => {
  if (typeof window === 'undefined') return;
  localStorage.setItem('interviewSidebarVisible', JSON.stringify(visible));
};

interface InterviewSidebarState {
  interviewSidebarVisible: boolean;
  setInterviewSidebarVisible: (visible: boolean) => void;
  toggleInterviewSidebar: () => void;
}

export const useInterviewSidebarStore = create<InterviewSidebarState>((set) => ({
  interviewSidebarVisible: getSidebarVisibilityFromStorage(),
  setInterviewSidebarVisible: (visible: boolean) => {
    saveSidebarVisibilityToStorage(visible);
    set({ interviewSidebarVisible: visible });
  },
  toggleInterviewSidebar: () => {
    set((state) => {
      const newVisibility = !state.interviewSidebarVisible;
      saveSidebarVisibilityToStorage(newVisibility);
      return { interviewSidebarVisible: newVisibility };
    });
  },
}));