import { QueryClient } from "@tanstack/react-query";
import { logError } from "@/utils/errorUtils";

/**
 * Create a configured QueryClient with standardized options
 */
export const createQueryClient = (): QueryClient => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // Data is considered fresh for 5 minutes by default
        staleTime: 1000 * 60 * 5,
        
        // Only retry failed queries once
        retry: 1,
        
        // Automatically refetch when window regains focus
        refetchOnWindowFocus: true,
        
        // Refresh data when reconnecting after offline period
        refetchOnReconnect: true,
        
        // Default error handling for all queries
        onError: (error) => {
          logError(error, "React Query");
        },
      },
      mutations: {
        // Default error handling for all mutations
        onError: (error) => {
          logError(error, "React Query Mutation");
        },
      },
    },
  });
};

/**
 * Singleton pattern for the QueryClient
 */
export const queryClient = createQueryClient();

export default queryClient;