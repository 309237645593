// Re-export MUI ThemeProvider with a different name to avoid naming conflicts
import {
  alpha,
  createTheme,
  PaletteMode,
  responsiveFontSizes,
  Theme,
  ThemeOptions,
  ThemeProvider as ThemeProvider_MUI,
  useTheme as useMuiTheme,
} from '@mui/material/styles';
import React, { createContext, useContext, useMemo, useState } from 'react';

// Define the ColorModeContext type
type ColorModeContextType = {
  mode: PaletteMode;
  toggleColorMode: () => void;
};

// Create the context
export const ColorModeContext = createContext<ColorModeContextType>({
  mode: "dark",
  toggleColorMode: () => {},
});

// Custom hook to use the color mode context
export const useColorMode = () => useContext(ColorModeContext);

// Custom hook for easier theme access with TypeScript support
export const useAppTheme = () => {
  return useMuiTheme();
};

// Specialized hooks for specific theme properties
export const useCustom = () => {
  const theme = useMuiTheme();
  return theme.custom;
};

export const useFlexbox = () => {
  const theme = useMuiTheme();
  return theme.custom.flexbox;
};

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      scrollbars: {
        classic: (theme: Theme) => Record<string, any>;
      };
      flexbox: {
        row: {
          start: Record<string, any>;
          center: Record<string, any>;
          centerAligned: Record<string, any>;
          end: Record<string, any>;
          between: Record<string, any>;
          around: Record<string, any>;
          evenly: Record<string, any>;
        };
        column: {
          start: Record<string, any>;
          center: Record<string, any>;
          centerAligned: Record<string, any>;
          end: Record<string, any>;
          between: Record<string, any>;
          around: Record<string, any>;
          evenly: Record<string, any>;
        };
        align: {
          start: Record<string, any>;
          center: Record<string, any>;
          end: Record<string, any>;
          stretch: Record<string, any>;
          baseline: Record<string, any>;
        };
        center: Record<string, any>;
      };
      effects: {
        gradients: {
          primary: string;
          secondary: string;
          accent: string;
        };
        patterns: {
          dots: string;
          grid: string;
        };
      };
      shadows: {
        soft: string;
        medium: string;
        strong: string;
      };
    };
    transitions: {
      menuFast: string;
    };
  }

  interface ThemeOptions {
    custom?: {
      scrollbars?: {
        classic?: (theme: Theme) => Record<string, any>;
      };
      flexbox?: {
        row?: {
          start?: Record<string, any>;
          center?: Record<string, any>;
          centerAligned?: Record<string, any>;
          end?: Record<string, any>;
          between?: Record<string, any>;
          around?: Record<string, any>;
          evenly?: Record<string, any>;
        };
        column?: {
          start?: Record<string, any>;
          center?: Record<string, any>;
          centerAligned?: Record<string, any>;
          end?: Record<string, any>;
          between?: Record<string, any>;
          around?: Record<string, any>;
          evenly?: Record<string, any>;
        };
        align?: {
          start?: Record<string, any>;
          center?: Record<string, any>;
          end?: Record<string, any>;
          stretch?: Record<string, any>;
          baseline?: Record<string, any>;
        };
        center?: Record<string, any>;
      };
      effects?: {
        gradients?: {
          primary?: string;
          secondary?: string;
          accent?: string;
        };
        patterns?: {
          dots?: string;
          grid?: string;
        };
      };
      shadows?: {
        soft?: string;
        medium?: string;
        strong?: string;
      };
    };
  }
}

// Create the base theme options with mode-specific palettes
const getThemeOptions = (mode: PaletteMode): ThemeOptions => {
  // Colors that change based on theme mode
  const primaryColor = mode === 'light' ? "rgba(36, 33, 43, 1)" : "rgba(36, 33, 43, 1)"; // Dark purple/black
  const secondaryColor = mode === 'light' ? "rgba(183, 160, 231, 1)" : "rgba(183, 160, 231, 1)"; // Lavender
  const backgroundColor = mode === 'light' ? "#f8f9fc" : "#121218"; // Light grey vs very dark purple-black
  const paperColor = mode === 'light' ? "#ffffff" : "#1e1e24"; // White vs dark purple-grey
  const textPrimaryColor = mode === 'light' ? "rgba(36, 33, 43, 1)" : "#f8f9fc"; // Dark text vs light text
  const textSecondaryColor = mode === 'light' ? "#5c5c5c" : "#b0b0b0"; // Medium grey vs light grey

  return {
    palette: {
      mode,
      primary: {
        main: primaryColor,
        light: mode === 'light' ? "#3d3a4a" : "#484458", 
        dark: mode === 'light' ? "#1a181f" : "#0f0d14",
        contrastText: mode === 'light' ? "#ffffff" : "#ffffff",
      },
      secondary: {
        main: secondaryColor,
        light: mode === 'light' ? "#d0c6ea" : "#c6b8e6",
        dark: mode === 'light' ? "#9a89c7" : "#8573bc",
        contrastText: mode === 'light' ? "#24212b" : "#24212b", 
      },
      background: {
        default: backgroundColor,
        paper: paperColor,
        dark: "#1e1e24", // For dark mode
        darkPaper: "#2a2a36", // For dark mode paper
      },
      text: {
        primary: textPrimaryColor,
        secondary: textSecondaryColor,
        disabled: mode === 'light' ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.38)",
        hint: mode === 'light' ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.38)",
      },
      action: {
        hover: alpha(secondaryColor, 0.08),
        selected: alpha(secondaryColor, 0.16),
        disabled: mode === 'light' ? "rgba(0, 0, 0, 0.26)" : "rgba(255, 255, 255, 0.3)",
        disabledBackground: mode === 'light' ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.12)",
      },
      info: {
        main: "#b4a3e7", // Your primary purple
        light: "#E1D9F3", // Very light lavender
        dark: "#8677c9",
        contrastText: "#ffffff",
      },
      error: {
        main: "#f44336",
        light: "#e57373",
        dark: "#d32f2f",
        contrastText: "#ffffff",
      },
      warning: {
        main: "#ff9800",
        light: "#ffb74d",
        dark: "#f57c00",
        contrastText: mode === 'light' ? "rgba(0, 0, 0, 0.87)" : "#ffffff",
      },
      success: {
        main: mode === 'light' ? "#a8d5aa" : "#88c28a",
        light: mode === 'light' ? "#d1ebd2" : "#aad4ac",
        dark: mode === 'light' ? "#7eba81" : "#5a9c5d",
        contrastText: mode === 'light' ? "rgba(0, 0, 0, 0.87)" : "#ffffff",
      },
      grey: {
        50: "#fafafa",
        100: "#f5f5f5",
        200: "#eeeeee",
        300: "#e0e0e0",
        400: "#bdbdbd",
        500: "#9e9e9e",
        600: "#757575",
        700: "#616161",
        800: "#424242",
        900: "#212121",
        A100: "#f5f5f5",
        A200: "#eeeeee",
        A400: "#bdbdbd",
        A700: "#616161",
      },
    },
    custom: {
      scrollbars: {
        classic: (theme) => ({
          "&::-webkit-scrollbar": { width: "6px" },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.action.selected,
            borderRadius: "3px",
          },
          "&::-webkit-scrollbar-track": { background: "transparent" },
          scrollbarWidth: "thin",
          scrollbarColor: "#E1D9F3 transparent",
        }),
      },
      flexbox: {
        row: {
          start: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          },
          center: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          },
          centerAligned: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          },
          end: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          },
          between: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          },
          around: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          },
          evenly: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          },
        },
        column: {
          start: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          },
          center: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          },
          centerAligned: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          },
          end: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          },
          between: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
          around: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          },
          evenly: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          },
        },
        align: {
          start: { alignItems: "flex-start" },
          center: { alignItems: "center" },
          end: { alignItems: "flex-end" },
          stretch: { alignItems: "stretch" },
          baseline: { alignItems: "baseline" },
        },
        center: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
      effects: {
        gradients: {
          primary: `linear-gradient(135deg, ${alpha(
            secondaryColor,
            0.8
          )} 0%, ${alpha(secondaryColor, 0.4)} 100%)`,
          secondary: `linear-gradient(135deg, ${alpha(
            primaryColor,
            0.9
          )} 0%, ${alpha(primaryColor, 0.6)} 100%)`,
          accent: `linear-gradient(135deg, #E1D9F3 0%, rgba(183, 160, 231, 0.5) 100%)`,
        },
        patterns: {
          dots: `radial-gradient(${alpha(
            secondaryColor,
            0.2
          )} 1px, transparent 1px)`,
          grid: `linear-gradient(to right, ${alpha(
            secondaryColor,
            0.05
          )} 1px, transparent 1px),
               linear-gradient(to bottom, ${alpha(
            secondaryColor,
            0.05
          )} 1px, transparent 1px)`,
        },
      },
      shadows: {
        soft: `0 4px 20px ${alpha(primaryColor, mode === 'light' ? 0.08 : 0.15)}`,
        medium: `0 8px 30px ${alpha(primaryColor, mode === 'light' ? 0.12 : 0.25)}`,
        strong: `0 12px 40px ${alpha(primaryColor, mode === 'light' ? 0.16 : 0.35)}`,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: (theme) => ({
          body: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          },
        }),
      },
      MuiCard: {
        styleOverrides: {
          root: ({ theme }) => ({
            boxShadow: mode === 'light'
              ? "0 4px 12px rgba(0, 0, 0, 0.05)"
              : "0 4px 12px rgba(0, 0, 0, 0.2)",
            borderRadius: 8,
            overflow: "hidden",
            transition: "all 0.25s cubic-bezier(0.4, 0, 0.2, 1)",
            backgroundColor: theme.palette.background.paper,
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: theme.palette.background.paper,
            boxShadow: mode === 'light'
              ? "0 2px 12px rgba(0, 0, 0, 0.05)"
              : "0 2px 12px rgba(0, 0, 0, 0.2)",
            borderRadius: 12,
            overflow: "hidden",
          }),
        },
        variants: [
          {
            props: { variant: "outlined" },
            style: ({ theme }) => ({
              border: `1px solid ${alpha(theme.palette.primary.main, 0.08)}`,
              boxShadow: "none",
            }),
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ theme }) => ({
            "& .MuiOutlinedInput-root": {
              borderRadius: 8,
              transition: "all 0.25s cubic-bezier(0.4, 0, 0.2, 1)",
              "&.Mui-focused": {
                boxShadow: `0 0 0 2px ${alpha(theme.palette.secondary.main, 0.2)}`,
              },
              backgroundColor: mode === 'dark' ? alpha("#2a2a36", 0.8) : undefined,
            },
            // Style for the label
            "& .MuiInputLabel-root": {
              color: mode === 'dark' 
                ? alpha(theme.palette.common.white, 0.7)
                : theme.palette.text.secondary,
              transition: "color 0.2s ease",
              "&.Mui-focused": {
                color: mode === 'dark'
                  ? theme.palette.secondary.light
                  : theme.palette.secondary.main,
              },
              "&.Mui-error": {
                color: theme.palette.error.main,
              },
            },
            // Style for the helper text
            "& .MuiFormHelperText-root": {
              color: mode === 'dark'
                ? alpha(theme.palette.common.white, 0.5)
                : theme.palette.text.secondary,
              "&.Mui-error": {
                color: mode === 'dark'
                  ? theme.palette.error.light
                  : theme.palette.error.main,
              },
            },
            // Improve outlined notch styling
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: mode === 'dark'
                ? alpha(theme.palette.divider, 0.3)
                : theme.palette.divider,
              transition: "border-color 0.2s ease",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: mode === 'dark'
                ? alpha(theme.palette.divider, 0.5)
                : alpha(theme.palette.primary.main, 0.5),
            },
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: mode === 'dark'
                ? theme.palette.secondary.main
                : theme.palette.secondary.main,
              borderWidth: '1.5px',
            },
          }),
        },
        variants: [
          {
            props: { variant: "authField" },
            style: ({ theme }) => ({
              "& .MuiOutlinedInput-root": {
                backgroundColor: mode === 'dark'
                  ? alpha(theme.palette.background.paper, 0.4) 
                  : alpha(theme.palette.background.paper, 0.8),
                "&:hover": {
                  backgroundColor: mode === 'dark'
                    ? alpha(theme.palette.background.paper, 0.5)
                    : theme.palette.background.paper,
                },
                "&.Mui-focused": {
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: `0 0 0 3px ${alpha(
                    theme.palette.secondary.main,
                    mode === 'dark' ? 0.3 : 0.2
                  )}`,
                },
              },
              "& .MuiInputLabel-root": {
                color: mode === 'dark'
                  ? alpha(theme.palette.common.white, 0.7)
                  : alpha(theme.palette.text.primary, 0.7),
              },
            }),
          },
        ],
      },
      MuiSelect: {
        styleOverrides: {
          outlined: ({ theme }) => ({
            borderRadius: 8,
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.secondary.main,
            },
          }),
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: 6,
            fontWeight: 500,
          },
          filled: ({ theme }) => ({
            backgroundColor: alpha(theme.palette.secondary.main, 0.1),
            color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.secondary.light,
            "&:hover": {
              backgroundColor: alpha(theme.palette.secondary.main, 0.2),
            },
          }),
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: ({ theme }) => ({
            border: `2px solid ${theme.palette.background.paper}`,
          }),
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: ({ theme }) => ({
            borderRadius: 12,
            boxShadow: theme.custom.shadows.medium,
            marginTop: 8,
          }),
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: "10px 16px",
            borderRadius: 6,
            margin: "2px 6px",
            "&:hover": {
              backgroundColor: alpha(theme.palette.secondary.main, 0.08),
            },
            "&.Mui-selected": {
              backgroundColor: alpha(theme.palette.secondary.main, 0.12),
              "&:hover": {
                backgroundColor: alpha(theme.palette.secondary.main, 0.16),
              },
            },
          }),
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 16,
            boxShadow: "0 12px 40px rgba(0, 0, 0, 0.12)",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: ({ theme }) => ({
            borderRight: `1px solid ${alpha(theme.palette.divider, 0.5)}`,
            boxShadow: theme.custom.shadows.medium,
          }),
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: alpha(theme.palette.background.paper, mode === 'light' ? 0.8 : 0.9),
            backdropFilter: "blur(10px)",
            boxShadow: mode === 'light' 
              ? "0 2px 10px rgba(0, 0, 0, 0.05)" 
              : "0 2px 10px rgba(0, 0, 0, 0.2)",
            borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
            color: theme.palette.text.primary,
          }),
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            transition: "color 0.2s ease",
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: ({ theme }) => ({
            width: 42,
            height: 26,
            padding: 0,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              margin: 2,
              transitionDuration: '300ms',
              '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
                  opacity: 1,
                  border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
              '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: theme.palette.secondary.main,
                border: '6px solid #fff',
              },
              '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
              },
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: 22,
              height: 22,
            },
            '& .MuiSwitch-track': {
              borderRadius: 26 / 2,
              backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
              opacity: 1,
              transition: theme.transitions.create(['background-color'], {
                duration: 500,
              }),
            },
          }),
        },
      },
    },
    shape: {
      borderRadius: 3,
    },
    spacing: 8,
    typography: {
      fontFamily: '"Manrope", sans-serif',
      h1: {
        fontWeight: 700,
        fontSize: "2.5rem",
        lineHeight: 1.2,
        letterSpacing: "-0.01em",
      },
      h2: {
        fontWeight: 700,
        fontSize: "2rem",
        lineHeight: 1.2,
        letterSpacing: "-0.01em",
      },
      h3: {
        fontWeight: 600,
        fontSize: "1.75rem",
        lineHeight: 1.2,
        letterSpacing: "-0.01em",
      },
      h4: {
        fontWeight: 600,
        fontSize: "1.5rem",
        lineHeight: 1.3,
        letterSpacing: "-0.01em",
      },
      h5: {
        fontWeight: 600,
        fontSize: "1.25rem",
        lineHeight: 1.4,
      },
      h6: {
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: 1.5,
      },
      subtitle1: {
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: 1.5,
      },
      subtitle2: {
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: 1.5,
      },
      body1: {
        fontSize: "1rem",
        lineHeight: 1.5,
      },
      body2: {
        fontSize: "0.875rem",
        lineHeight: 1.5,
      },
      button: {
        fontSize: "0.875rem",
        fontWeight: 600,
        textTransform: "none",
        letterSpacing: "0.02em",
      },
      caption: {
        fontSize: "0.75rem",
        lineHeight: 1.4,
        letterSpacing: "0.01em",
      },
      overline: {
        fontSize: "0.75rem",
        fontWeight: 600,
        textTransform: "uppercase",
        letterSpacing: "0.08em",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  };
};

// Create a theme provider component
export const ThemeProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  // Get stored theme mode or default to light
  const [mode, setMode] = useState<PaletteMode>(() => {
    const storedMode = localStorage.getItem('themeMode');
    return (storedMode as PaletteMode) || 'dark';
  });

  // Toggle theme mode
  const colorMode = useMemo(
    () => ({
      mode,
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('themeMode', newMode);
          return newMode;
        });
      },
    }),
    [mode]
  );

  // Create theme based on current mode
  const theme = useMemo(() => {
    const baseTheme = createTheme(getThemeOptions(mode));
    return responsiveFontSizes(baseTheme);
  }, [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider_MUI theme={theme}>{children}</ThemeProvider_MUI>
    </ColorModeContext.Provider>
  );
};

// Add type extension for the background property
declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    dark: string;
    darkPaper: string;
  }
}

// Export a default theme for direct imports (for backward compatibility)
const defaultTheme = createTheme(getThemeOptions('dark'));
export default responsiveFontSizes(defaultTheme);