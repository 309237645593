import axios from "axios";
import { TokenData } from "@/types/auth";
import { API_URL } from "./apiConfig";

// Constants
const TOKEN_REFRESH_BUFFER = 300000; // 5 minutes

// Custom event for auth logout
export interface AuthLogoutEvent extends CustomEvent {
  detail: {
    reason: string;
  };
}

/**
 * Token Manager - Responsible for all token-related operations
 */
class TokenManager {
  private refreshPromise: Promise<string> | null = null;
  
  /**
   * Get access token from session storage
   */
  getAccessToken(): string | null {
    return sessionStorage.getItem("access_token");
  }
  
  /**
   * Get refresh token from session storage
   */
  getRefreshToken(): string | null {
    return sessionStorage.getItem("refresh_token");
  }
  
  /**
   * Get auth session from session storage
   */
  getAuthSession(): string | null {
    return sessionStorage.getItem("auth_session");
  }
  
  /**
   * Save tokens to session storage
   */
  setTokens({ access_token, refresh_token }: TokenData): void {
    sessionStorage.setItem("access_token", access_token);
    sessionStorage.setItem("refresh_token", refresh_token);
  }
  
  /**
   * Save auth session to session storage
   */
  setAuthSession(authSession: string): void {
    sessionStorage.setItem("auth_session", authSession);
  }
  
  /**
   * Clear tokens from session storage
   */
  clearTokens(): void {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
  }
  
  /**
   * Clear auth session from session storage
   */
  clearAuthSession(): void {
    sessionStorage.removeItem("auth_session");
  }
  
  /**
   * Check if tokens exist
   */
  hasTokens(): boolean {
    return Boolean(this.getAccessToken() && this.getRefreshToken());
  }
  
  /**
   * Decode access token and extract expiration time
   */
  getAccessTokenExpiration(): number {
    const token = this.getAccessToken();
    if (!token) return 0;
    
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload.exp * 1000; // Convert to milliseconds
    } catch (error) {
      console.error("Token decoding error:", error);
      return 0;
    }
  }
  
  /**
   * Check if the access token is about to expire
   */
  isAccessTokenAboutToExpire(): boolean {
    const expiration = this.getAccessTokenExpiration();
    return expiration ? Date.now() >= expiration - TOKEN_REFRESH_BUFFER : true;
  }
  
  /**
   * Refresh token with request deduplication
   */
  async refreshToken(): Promise<string> {
    // Return existing promise if refresh is already in progress
    if (this.refreshPromise) {
      return this.refreshPromise;
    }
    
    try {
      this.refreshPromise = this._performRefresh();
      return await this.refreshPromise;
    } finally {
      this.refreshPromise = null;
    }
  }
  
  /**
   * Perform the actual token refresh
   */
  private async _performRefresh(): Promise<string> {
    const refreshToken = this.getRefreshToken();
    
    if (!refreshToken) {
      this.handleSessionExpiration();
      throw new Error("No refresh token available");
    }
    
    try {
      const { data } = await axios.post<TokenData>(
        `${API_URL}/api/public/auth/refresh`,
        null,
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }
      );
      
      this.setTokens(data);
      return data.access_token;
    } catch (error) {
      this.handleSessionExpiration();
      throw error;
    }
  }
  
  /**
   * Handle session expiration by dispatching a logout event
   */
  handleSessionExpiration(reason = "session_expired"): void {
    window.dispatchEvent(
      new CustomEvent("auth-logout", {
        detail: { reason },
      }) as AuthLogoutEvent
    );
  }
}

// Export a singleton instance
export const tokenManager = new TokenManager();

// Setup global logout event listener
window.addEventListener("auth-logout", (event: Event) => {
  tokenManager.clearTokens();
  const customEvent = event as AuthLogoutEvent;
  const reason = customEvent.detail?.reason || "session_expired";
  
  // Use custom event for React Router navigation instead of direct window.location.href
  window.dispatchEvent(
    new CustomEvent("auth-navigate", {
      detail: { 
        path: `/login?reason=${reason}`,
        state: { authError: true, reason }
      },
    })
  );
});