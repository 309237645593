import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import App from "./App";
import { ThemeProvider } from "./themeStyle";
import { SnackbarProvider } from "notistack";
import Loading from "./components/Common/Loading";
import { ErrorBoundary } from "@/components/Common/ErrorHandling";
import queryClient from "@/services/queryClient";

// Set locale for dayjs
dayjs.locale("en-gb");

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(rootElement);

// Error handler for the root ErrorBoundary
const handleRootError = (error: Error): void => {
  console.error("Root level error:", error);
  // In production, this would send to an error monitoring service
};

root.render(
  <ErrorBoundary onError={handleRootError}>
    <ThemeProvider>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <SnackbarProvider 
              maxSnack={6}
              anchorOrigin={{ 
                vertical: 'bottom', 
                horizontal: 'right' 
              }}
              autoHideDuration={5000}
            >
              <Suspense fallback={<Loading />}>
                <App />
              </Suspense>
            </SnackbarProvider>
          </Router>
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </ErrorBoundary>
);