import React, { lazy, Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Workspace from "./pages/Workspace";
import Loading from "./components/Common/Loading";
import { navigationService } from "@/services/auth/navigationService";

// Simple lazy imports
const LandingPage = lazy(() => import("./components/Landing/LandingPage"));
const Registration = lazy(() => import("./pages/Registration"));
const Authentication = lazy(() => import("./pages/Authentication"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Profile = lazy(() => import("./pages/Profile"));
const Settings = lazy(() => import("./pages/Settings"));
// const NotFound = lazy(() => import("./pages/NotFound"));

// Interviews module
const Interviews = lazy(() => import("./pages/Interviews"));
const InterviewBoard = lazy(() => import("./components/Feature/Interviews/InterviewBoard"));
const InterviewDetails = lazy(() => import("./components/Feature/Interviews/Details/InterviewDetails.tsx"));
const InterviewAssistant = lazy(() => import("./components/Feature/Interviews/Chat/InterviewAssistant"));

// Knowledge module
const InterviewSurvey = lazy(() => import("./components/Knowledge/Questions/Survey.tsx"));

// Other modules
const Sales = lazy(() => import("./pages/Sales"));
const Reports = lazy(() => import("./pages/Reports"));
const Knowledge = lazy(() => import("./pages/Knowledge"));

const App: React.FC = () => {
    const navigate = useNavigate();
    
    // Initialize the navigation service with React Router's navigate function
    useEffect(() => {
        navigationService.setNavigate(navigate);
    }, [navigate]);
    
    return (
          <Routes>
              <Route path="/" element={
                <Suspense fallback={<Loading />}>
                  <LandingPage/>
                </Suspense>
              }/>
              <Route path="/registration" element={
                <Suspense fallback={<Loading />}>
                  <Registration/>
                </Suspense>
              }/>
              <Route path="/login" element={
                <Suspense fallback={<Loading />}>
                  <Authentication/>
                </Suspense>
              }/>

              <Route path="/app" element={<Workspace/>}>
                  <Route index element={<Navigate to="dashboard" replace/>}/>

                  <Route path="interviews" element={
                    <Suspense fallback={<Loading />}>
                      <Interviews/>
                    </Suspense>
                  }>
                      <Route index element={
                        <Suspense fallback={<Loading />}>
                          <InterviewBoard/>
                        </Suspense>
                      }/>
                      <Route path=":interviewUuid" element={
                        <Suspense fallback={<Loading />}>
                          <InterviewDetails/>
                        </Suspense>
                      }/>
                      <Route path=":interviewUuid/preparation" element={
                        <Suspense fallback={<Loading />}>
                          <InterviewAssistant/>
                        </Suspense>
                      }/>
                  </Route>

                  {/* Other main sections */}
                  <Route path="dashboard" element={
                    <Suspense fallback={<Loading />}>
                      <Dashboard/>
                    </Suspense>
                  }/>
                  <Route path="sales" element={
                    <Suspense fallback={<Loading />}>
                      <Sales/>
                    </Suspense>
                  }/>

                  <Route path="knowledge" element={
                    <Suspense fallback={<Loading />}>
                      <Knowledge/>
                    </Suspense>
                  }>
                    <Route path="interviews/:interviewId/survey" element={
                      <Suspense fallback={<Loading />}>
                        <InterviewSurvey/>
                      </Suspense>
                    } />
                  </Route>

                  <Route path="reports" element={
                    <Suspense fallback={<Loading />}>
                      <Reports/>
                    </Suspense>
                  }/>
                  <Route path="profile" element={
                    <Suspense fallback={<Loading />}>
                      <Profile/>
                    </Suspense>
                  }/>
                  <Route path="settings" element={
                    <Suspense fallback={<Loading />}>
                      <Settings/>
                    </Suspense>
                  }/>
              </Route>

              {/*<Route path="*" element={<NotFound/>}/>*/}
          </Routes>
    );
};

export default App;