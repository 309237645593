import axios, { AxiosError } from "axios";
import { ErrorType } from "@/components/Common/ErrorHandling/QueryErrorState";

/**
 * Extracts error details from various error types
 * @param error - The error object
 * @returns Standardized error details
 */
export const extractErrorDetails = (error: unknown): {
  message: string;
  type: ErrorType;
  status?: number;
} => {
  // Handle Axios errors
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    const status = axiosError.response?.status;
    
    // Network errors (no response)
    if (axiosError.code === "ECONNABORTED" || !axiosError.response) {
      return {
        message: "Unable to connect to the server. Please check your internet connection.",
        type: "network",
      };
    }
    
    // Handle different status codes
    if (status) {
      if (status === 401) {
        return {
          message: "Your session has expired. Please sign in again.",
          type: "unauthorized",
          status,
        };
      }
      
      if (status === 403) {
        return {
          message: "You don't have permission to access this resource.",
          type: "unauthorized",
          status,
        };
      }
      
      if (status === 404) {
        return {
          message: "The requested resource could not be found.",
          type: "notFound",
          status,
        };
      }
      
      if (status === 422) {
        const responseData = axiosError.response?.data as Record<string, unknown> | undefined;
        return {
          message: responseData?.message as string || "Validation error occurred.",
          type: "validation",
          status,
        };
      }
      
      if (status >= 500) {
        return {
          message: "Our servers are experiencing issues. Please try again later.",
          type: "server",
          status,
        };
      }
    }
    
    // Get the error message from the response
    const responseData = axiosError.response?.data as Record<string, unknown> | undefined;
    const responseMessage = responseData?.message as string || axiosError.message;
    
    return {
      message: responseMessage || "An error occurred while processing your request.",
      type: "unknown",
      status,
    };
  }
  
  // Handle standard JavaScript errors
  if (error instanceof Error) {
    return {
      message: error.message || "An unexpected error occurred.",
      type: "unknown",
    };
  }
  
  // Fallback for unknown error types
  return {
    message: "An unexpected error occurred.",
    type: "unknown",
  };
};

/**
 * Formats user-friendly error messages from API errors
 * @param error - The error object
 * @returns User-friendly error message
 */
export const formatErrorMessage = (error: unknown): string => {
  const { message } = extractErrorDetails(error);
  return message;
};

/**
 * Determines if an error is a network connectivity issue
 * @param error - The error object
 * @returns True if the error is a network issue
 */
export const isNetworkError = (error: unknown): boolean => {
  if (axios.isAxiosError(error)) {
    return !error.response || error.code === "ECONNABORTED";
  }
  return false;
};

/**
 * Determines if an error is a server error (5xx)
 * @param error - The error object
 * @returns True if the error is a server error
 */
export const isServerError = (error: unknown): boolean => {
  if (axios.isAxiosError(error) && error.response) {
    return error.response.status >= 500;
  }
  return false;
};

/**
 * Determines if an error is an authentication error (401/403)
 * @param error - The error object
 * @returns True if the error is an authentication error
 */
export const isAuthError = (error: unknown): boolean => {
  if (axios.isAxiosError(error) && error.response) {
    return error.response.status === 401 || error.response.status === 403;
  }
  return false;
};

/**
 * Logs errors to the console in development and to a monitoring service in production
 * @param error - The error object
 * @param context - Additional context about where the error occurred
 */
export const logError = (error: unknown, context?: string): void => {
  if (process.env.NODE_ENV === "development") {
    console.error(`Error${context ? ` in ${context}` : ""}:`, error);
  } else {
    // In production, send to error monitoring service
    // Example: errorMonitoringService.captureException(error, { extra: { context } });
    
    // For now, just log to console in production as well
    console.error(`Error${context ? ` in ${context}` : ""}:`, error);
  }
};