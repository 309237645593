import { create } from "zustand";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, ReactNode } from "react";
import { fetchWorkspaces, Workspace } from "@/services/user/profile";

interface WorkspaceState {
  selectedWorkspace: Workspace | null;
  setSelectedWorkspace: (workspace: Workspace | null) => void;
}

export const useWorkspaceStore = create<WorkspaceState>((set) => ({
  selectedWorkspace: null,
  setSelectedWorkspace: (workspace) => set({ selectedWorkspace: workspace }),
}));

export const useWorkspace = (): Workspace | null =>
  useWorkspaceStore((state) => state.selectedWorkspace);

export const useWorkspaces = () => {
  const setSelectedWorkspace = useWorkspaceStore(
    (state) => state.setSelectedWorkspace
  );

  return useQuery<Workspace[], Error>({
    queryKey: ["workspaces"],
    queryFn: async () => {
      const workspaces = await fetchWorkspaces();

      const savedWorkspace = useWorkspaceStore.getState().selectedWorkspace;
      const isValidSaved =
        savedWorkspace && workspaces.some((ws) => ws.id === savedWorkspace.id);

      if (!isValidSaved && workspaces?.length > 0) {
        setSelectedWorkspace(workspaces[0]);
      }

      return workspaces;
    },
    staleTime: 5 * 60 * 1000,
    onSuccess: (workspaces) => {
      const current = useWorkspaceStore.getState().selectedWorkspace;
      if (current && !workspaces.some((ws) => ws.id === current.id)) {
        setSelectedWorkspace(workspaces[0] || null);
      }
    },
  });
};

interface WorkspaceProviderProps {
  children: ReactNode;
}

export const WorkspaceProvider: React.FC<WorkspaceProviderProps> = ({ children }) => {
  const { data: workspaces } = useWorkspaces();
  const selectedWorkspace = useWorkspaceStore(
    (state) => state.selectedWorkspace
  );
  const setSelectedWorkspace = useWorkspaceStore(
    (state) => state.setSelectedWorkspace
  );

  useEffect(() => {
    if (workspaces?.length > 0 && !selectedWorkspace) {
      setSelectedWorkspace(workspaces[0]);
    }
  }, [workspaces, selectedWorkspace, setSelectedWorkspace]);

  return children;
};

// Uncomment and use if needed
// export const WorkspaceSelector: React.FC = () => {
//     const {data: workspaces} = useWorkspaces();
//     const selectedWorkspace = useWorkspaceStore((state) => state.selectedWorkspace);
//     const setSelectedWorkspace = useWorkspaceStore((state) => state.setSelectedWorkspace);
//
//     return (
//         <Select
//             value={selectedWorkspace?.id || ''}
//             onChange={(e: SelectChangeEvent<string>) => {
//                 const workspace = workspaces?.find(ws => ws.id === e.target.value);
//                 if (workspace) setSelectedWorkspace(workspace);
//             }}
//         >
//             {workspaces?.map(ws => (
//                 <MenuItem key={ws.id} value={ws.id}>
//                     {ws.name}
//                 </MenuItem>
//             ))}
//         </Select>
//     );
// };